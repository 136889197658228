import React, { Suspense } from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { Router, Route } from 'react-router-dom'
import { Login } from 'views'
import { browserHistory } from 'providers/router/BrowserHistory'
import { getPageTitle } from 'utils/SiteUtils'
import { Authorization } from 'providers/auth'
import ErrorBlock from 'components/ErrorBlock'
import VeyronTheme from 'theme/Veyron'
import {
  preloadFirestore,
  preloadStorage,
  preloadAuth,
  useFirebaseApp,
  SuspenseWithPerf,
  useAnalytics
} from 'reactfire'
import Loading from 'components/Loading'
import { SnackbarContext, useToast } from 'contexts'
import Snackbar from 'components/Snackbar'
import ConfirmProvider from 'providers/ConfirmProvider'
import routes from './routings'
import { Switch } from 'react-router-dom'

// Our components will lazy load the SDKs to decrease their bundle size.
// Since we know that, we can start fetching them now
const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadFirestore({
      firebaseApp: firebaseApp,
      setup: (firestore) => {
        firestore().clearPersistence().then()
        firestore().enablePersistence({ synchronizeTabs: true }).then()
      }
    }),
    preloadAuth({
      firebaseApp: firebaseApp
    }),
    preloadStorage({
      firebaseApp: firebaseApp,
      setup: (storage) => storage().setMaxUploadRetryTime(10000)
    })
  ])
}

const MyPageViewLogger: React.FC = () => {
  const analytics = useAnalytics()
  const { location } = browserHistory

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      analytics.logEvent('page-view', { path_name: location.pathname })
    }
  }, [location.pathname, analytics])

  return null
}

function App() {
  const firebaseApp = useFirebaseApp()
  preloadSDKs(firebaseApp)

  const toastProps = useToast()

  document.title = getPageTitle()

  return (
    <ThemeProvider theme={VeyronTheme}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <ConfirmProvider>
          <SnackbarContext.Provider value={toastProps}>
            <Snackbar />
            <Router history={browserHistory}>
              <Switch>
                <Route exact path="/login" component={Login} />
                {routes.map((r) => (
                  <Route
                    exact
                    key={r.path}
                    path={r.path}
                    render={(props) => (
                      <SuspenseWithPerf
                        fallback={<Loading />}
                        traceId={'view:' + r.path}
                      >
                        <Authorization
                          requiredClaims={r.requiredClaims}
                          component={r.component}
                          {...props}
                        />
                      </SuspenseWithPerf>
                    )}
                  />
                ))}

                <Route render={() => <ErrorBlock variant="404" />} />
              </Switch>
            </Router>
          </SnackbarContext.Provider>
        </ConfirmProvider>
        <MyPageViewLogger />
      </Suspense>
    </ThemeProvider>
  )
}

export default App
