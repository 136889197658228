import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import firebaseConfig from 'configs/firebase-config.json'
import { FirebaseAppProvider } from 'reactfire'

const app = document.getElementById('root')
app?.setAttribute('notranslate', 'true')

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div>Loading ...</div>}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <App />
      </FirebaseAppProvider>
    </React.Suspense>
  </React.StrictMode>,
  app
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
