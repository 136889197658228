
export const ROLES: {
    COURSES_VIEWER: "veyron:courses:view",
    LECTURES_VIEWER: "veyron:lectures:view",
    SCHEMES_VIEWER: "veyron:schemes:view",
    ADMIN: "admin",
    PAYMENT_ADMIN: "payment:admin",
    WORKFLOW_ADMIN: "veyron:workflow:admin",
} = {
    COURSES_VIEWER: "veyron:courses:view",
    LECTURES_VIEWER: "veyron:lectures:view",
    SCHEMES_VIEWER: "veyron:schemes:view",
    ADMIN: "admin",
    PAYMENT_ADMIN: "payment:admin",
    WORKFLOW_ADMIN: "veyron:workflow:admin",

}


const Roles: {
    key: string
    displayName: string
    description: string
}[] = [
    {
        key: ROLES.ADMIN,
        displayName: "System administrator",
        description: "User can manage all agilearn's system"
    },
    {
        key: ROLES.PAYMENT_ADMIN,
        displayName: "Payment administrator",
        description: "User can manage payment system"
    },
    {
        key: ROLES.WORKFLOW_ADMIN,
        displayName: "Workflow admin",
        description: "User can manage workflow"
    },
    {
    key: ROLES.COURSES_VIEWER,
    displayName: "Course viewer",
    description: "User can view courses"
},
{
    key: ROLES.LECTURES_VIEWER,
    displayName: "Lecture viewer",
    description: "User can view lectures"
},
{
    key: ROLES.SCHEMES_VIEWER,
    displayName: "Scheme viewer",
    description: "User can view schemes"
}

]


export default Roles