import React, { useContext } from 'react'
import { Snackbar as MatSnackbar, SnackbarOrigin } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { SnackbarContext } from 'contexts/SnackbarContext'

const AUTO_HIDE_DURATION = 1e4 // 10 sec
const anchorOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center'
}

const Snackbar: React.FC = () => {
  const { toast, closeToast } = useContext(SnackbarContext)

  return (
    <MatSnackbar
      anchorOrigin={anchorOrigin}
      open={Boolean(toast.message)}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={closeToast}
    >
      <Alert onClose={closeToast} severity={toast.severity}>
        {toast.message}
      </Alert>
    </MatSnackbar>
  )
}

export default Snackbar
