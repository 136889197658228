import React from 'react'

export const Login = React.lazy(() => import('./Login'))
export const LecturesList = React.lazy(() => import('./LecturesList'))
export const LectureDetail = React.lazy(() => import('./LectureDetail'))
export const QuestionBanks = React.lazy(() => import('./QuestionBanks'))
export const QuestionBankDetail = React.lazy(() =>
  import('./QuestionBankDetail')
)
export const AddLecture = React.lazy(() => import('./AddLecture'))
export const AddActivity = React.lazy(() => import('./AddActivity'))
export const EditActivity = React.lazy(() => import('./EditActivity'))
export const CoursesList = React.lazy(() => import('./CoursesList'))
export const CourseDetail = React.lazy(() => import('./CourseDetail'))
export const AddSectionLecture = React.lazy(() => import('./AddSectionLecture'))
export const AddSectionQuiz = React.lazy(() => import('./AddSectionQuiz'))
export const AddQuestion = React.lazy(() => import('./AddQuestion'))
export const AddQuiz = React.lazy(() => import('./AddQuiz'))
export const AddUser = React.lazy(() => import('./AddUser'))
export const QuizDetail = React.lazy(() => import('./QuizDetail'))
export const EditQuestion = React.lazy(() => import('./EditQuestion'))
export const EditLecture = React.lazy(() => import('./EditLecture'))
export const Users = React.lazy(() => import('./Users'))
export const UserDetail = React.lazy(() => import('./UserDetail'))
export const CreateCourse = React.lazy(() => import('./CreateCourse'))
export const CertificationExams = React.lazy(() =>
  import('./CertificationExams')
)
export const CompetencyModels = React.lazy(() => import('./CompetencyModels'))
export const AddCertificationExam = React.lazy(() =>
  import('./AddCertificationExam')
)
export const CompetencyModelDetail = React.lazy(() =>
  import('./CompetencyModelDetail')
)
export const CompetencyModelCreateQuestion = React.lazy(() =>
  import('./CompetencyModelCreateQuestion')
)

export const CertificationDetail = React.lazy(() =>
  import('./CertificationDetail')
)
export const LectureLearningObjectives = React.lazy(() =>
  import('./LectureLearningObjectives')
)
export const EditCourse = React.lazy(() => import('./EditCourse'))
export const LearningSpaces = React.lazy(() => import('./LearningSpaces'))
export const AddLearningSpace = React.lazy(() => import('./AddLearningSpace'))
export const LearningObjectiveMove = React.lazy(() =>
  import('./LearningObjectiveMove')
)
export const EditLearningSpace = React.lazy(() => import('./EditLearningSpace'))
export const Schemes = React.lazy(() => import('./Schemes'))
export const SchemeDetail = React.lazy(() => import('./SchemeDetail'))
export const SchemeAddAssignment = React.lazy(() =>
  import('./SchemeAddAssignment')
)
export const SchemeAddCourseToSection = React.lazy(() =>
  import('./SchemeAddCourseToSection')
)
export const SchemeAddQuizToSection = React.lazy(() =>
  import('./SchemeAddQuizToSection')
)
export const SchemeAddAttachmentToSection = React.lazy(() =>
  import('./SchemeAddAttachmentToSection')
)
export const SchemeSectionAttachmentEdit = React.lazy(() =>
  import('./SchemeSectionAttachmentEdit')
)
export const SchemeAddExternalLink = React.lazy(() =>
  import('./SchemeAddExternalLink')
)

export const SchemeAddEvent = React.lazy(() => import('./SchemeAddEvent'))
export const SchemeEditAssignment = React.lazy(() =>
  import('./SchemeEditAssignment')
)
export const SchemeEditExternalLink = React.lazy(() =>
  import('./SchemeEditExternalLink')
)
export const SchemeEventEdit = React.lazy(() => import('./SchemeEventEdit'))
export const CreateScheme = React.lazy(() => import('./CreateScheme'))
export const Checklists = React.lazy(() => import('./Checklists'))
export const Checklist = React.lazy(() => import('./Checklist'))
export const Workflows = React.lazy(() => import('./Workflows'))
export const Workflow = React.lazy(() => import('./Workflow'))
export const SchemeAddChecklist = React.lazy(() =>
  import('./SchemeAddChecklist')
)

export const SchemeAddReflection = React.lazy(() =>
  import('./SchemeAddReflection')
)
