import React, { useMemo } from 'react'
import { useObservable, useUser } from 'reactfire'
import { Login } from 'views'
import { from } from 'rxjs'
import ErrorBlock from 'components/ErrorBlock'
import { Redirect, RouteProps } from 'react-router-dom'
import { hasAnyRoles } from '../../utils/Authorization'
import { ROLES } from '../../configs/roles'

export declare type AuthProps = {
  authUser: firebase.User
}

const getRedirectLinkByClaims = (claims: any) => {

  if (hasAnyRoles(claims, ROLES.ADMIN,ROLES.LECTURES_VIEWER)) return '/'
  else if (hasAnyRoles(claims, ROLES.COURSES_VIEWER)) return '/courses'
  else if (hasAnyRoles(claims, ROLES.SCHEMES_VIEWER)) return '/schemes'


  return undefined
}

export function useIdTokenResult(
  user: firebase.User,
  forceRefresh: boolean = false
) {
  if (!user) {
    throw new Error('you must provide a user')
  }

  const idToken$ = from(user.getIdTokenResult(forceRefresh))

  return useObservable<any>(
    idToken$,
    `auth:idTokenResult:${user.uid}:forceRefresh=${forceRefresh}`
  )
}

interface CheckClaimsProps extends RouteProps {
  component: React.ComponentType<AuthProps> | React.LazyExoticComponent<any>
  user: firebase.User
  requiredClaims: { [key: string]: boolean }
}
export const CheckClaims: React.FC<CheckClaimsProps> = (props) => {
  const { user, component: C, requiredClaims, ...rest } = props
  const { claims } = useIdTokenResult(user, false)

  const activeRequireRoles = useMemo(() => Object.keys(requiredClaims).filter((role) => requiredClaims[role]), [requiredClaims])

  const canAccess = useMemo(() => hasAnyRoles(claims, ...activeRequireRoles), [claims, activeRequireRoles])

  if (!canAccess) {

    const validLink = getRedirectLinkByClaims(claims)

    if (validLink) return <Redirect to={validLink} />

    return <ErrorBlock variant="403" />
  }

  return <C authUser={user} {...rest} />
}

interface IProps extends RouteProps {
  component: React.ComponentType<AuthProps> | React.LazyExoticComponent<any>
  requiredClaims?: { [key: string]: boolean }
}

export const Authorization: React.FC<IProps> = (props) => {
  const user = useUser<firebase.User>()
  const { component: C, requiredClaims, ...rest } = props

  if (!user) {
    return <Login />
  }

  return requiredClaims ? (
    <CheckClaims
      user={user}
      requiredClaims={requiredClaims}
      component={C}
      {...rest}
    />
  ) : (
    <C authUser={user} {...rest} />
  )
}
