import React from 'react'
import { ROLES } from './configs/roles'
import { AuthProps } from './providers/auth'
import {
  AddActivity,
  AddCertificationExam,
  AddLearningSpace,
  AddLecture,
  AddQuestion,
  AddQuiz,
  AddSectionLecture,
  AddSectionQuiz,
  AddUser,
  CertificationDetail,
  CertificationExams,
  Checklist,
  Checklists,
  CompetencyModelCreateQuestion,
  CompetencyModelDetail,
  CompetencyModels,
  CourseDetail,
  CoursesList,
  CreateCourse,
  CreateScheme,
  EditActivity,
  EditCourse,
  EditLearningSpace,
  EditLecture,
  EditQuestion,
  LearningObjectiveMove,
  LearningSpaces,
  LectureDetail,
  LectureLearningObjectives,
  LecturesList,
  QuestionBankDetail,
  QuestionBanks,
  QuizDetail,
  SchemeAddAssignment,
  SchemeAddAttachmentToSection,
  SchemeAddChecklist,
  SchemeAddCourseToSection,
  SchemeAddEvent,
  SchemeAddExternalLink,
  SchemeAddQuizToSection,
  SchemeAddReflection,
  SchemeDetail,
  SchemeEditAssignment,
  SchemeEditExternalLink,
  SchemeEventEdit,
  Schemes,
  SchemeSectionAttachmentEdit,
  UserDetail,
  Users,
  Workflow,
  Workflows
} from './views'

declare type PrivateRouteCfgType = {
  path: string
  component:
    | React.ComponentType<AuthProps>
    | React.LazyExoticComponent<React.ComponentType<AuthProps>>
  requiredClaims?: { [key: string]: boolean }
}

const routes: Array<PrivateRouteCfgType> = [
  {
    path: '/',
    component: LecturesList,
    requiredClaims: { admin: true, [ROLES.LECTURES_VIEWER]: true }
  },
  {
    path: '/lecture/add',
    component: AddLecture,
    requiredClaims: { admin: true }
  },
  {
    path: '/lecture/:id',
    component: LectureDetail,
    requiredClaims: { admin: true, [ROLES.LECTURES_VIEWER]: true }
  },
  {
    path: '/lecture/:id/learning-objectives',
    component: LectureLearningObjectives,
    requiredClaims: { admin: true }
  },
  {
    path: '/lecture/:id/edit',
    component: EditLecture,
    requiredClaims: { admin: true }
  },
  {
    path: '/lecture/:id/questionBanks',
    component: QuestionBanks,
    requiredClaims: { admin: true, [ROLES.LECTURES_VIEWER]: true }
  },
  {
    path: '/lecture/:id/questionBank/:qbId',
    component: QuestionBankDetail,
    requiredClaims: { admin: true, [ROLES.LECTURES_VIEWER]: true }
  },
  {
    path: '/lecture/:id/addAct',
    component: AddActivity,
    requiredClaims: { admin: true }
  },
  {
    path: '/lecture/:id/act/:actId/edit',
    component: EditActivity,
    requiredClaims: { admin: true }
  },
  {
    path: '/courses',
    component: CoursesList,
    requiredClaims: { admin: true, [ROLES.COURSES_VIEWER]: true }
  },
  {
    path: '/course/:id',
    component: CourseDetail,
    requiredClaims: { admin: true, [ROLES.COURSES_VIEWER]: true }
  },
  {
    path: '/course/:courseId/section/:sectionId/addLecture',
    component: AddSectionLecture,
    requiredClaims: { admin: true }
  },
  {
    path: '/course/:courseId/section/:sectionId/addQuiz',
    component: AddSectionQuiz,
    requiredClaims: { admin: true }
  },
  {
    path: '/lecture/:lectureId/questionBank/:questionBankId/question/add',
    component: AddQuestion,
    requiredClaims: { admin: true }
  },
  {
    path:
      '/lecture/:lectureId/questionBank/:questionBankId/question/:questionId/edit',
    component: EditQuestion,
    requiredClaims: { admin: true }
  },
  {
    path: '/course/:courseId/quiz/add',
    component: AddQuiz,
    requiredClaims: { admin: true }
  },
  {
    path: '/course/:courseId/quiz/:quizId',
    component: QuizDetail,
    requiredClaims: { admin: true, [ROLES.COURSES_VIEWER]: true }
  },
  {
    path: '/users/add',
    component: AddUser,
    requiredClaims: { admin: true }
  },
  {
    path: '/users',
    component: Users,
    requiredClaims: { admin: true }
  },
  {
    path: '/user/:userId',
    component: UserDetail,
    requiredClaims: { admin: true }
  },
  {
    path: '/create-course',
    component: CreateCourse,
    requiredClaims: { admin: true }
  },
  {
    path: '/competency-models',
    component: CompetencyModels,
    requiredClaims: { admin: true }
  },
  {
    path: '/certification-exams',
    component: CertificationExams,
    requiredClaims: { admin: true }
  },
  {
    path: '/certification-exams/:id',
    component: CertificationDetail,
    requiredClaims: { admin: true }
  },
  {
    path: '/add-certification-exam',
    component: AddCertificationExam,
    requiredClaims: { admin: true }
  },
  {
    path: '/competency-models/:id',
    component: CompetencyModelDetail,
    requiredClaims: { admin: true }
  },
  {
    path: '/competency-models/:id/create-question',
    component: CompetencyModelCreateQuestion,
    requiredClaims: { admin: true }
  },
  {
    path: '/learning-objective/move',
    component: LearningObjectiveMove,
    requiredClaims: { admin: true }
  },
  {
    path: '/edit-course/:id',
    component: EditCourse,
    requiredClaims: { admin: true }
  },
  {
    path: '/learning-spaces',
    component: LearningSpaces,
    requiredClaims: { admin: true }
  },
  {
    path: '/add-learning-space',
    component: AddLearningSpace,
    requiredClaims: { admin: true }
  },
  {
    path: '/edit-learning-space/:learningSpaceId',
    component: EditLearningSpace,
    requiredClaims: { admin: true }
  },
  //scheme
  {
    path: '/schemes',
    component: Schemes,
    requiredClaims: { admin: true, [ROLES.SCHEMES_VIEWER]: true }
  },
  {
    path: '/create-scheme',
    component: CreateScheme,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id',
    component: SchemeDetail,
    requiredClaims: { admin: true, [ROLES.SCHEMES_VIEWER]: true }
  },
  {
    path: '/scheme/:id/add-assignment',
    component: SchemeAddAssignment,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-reflection',
    component: SchemeAddReflection,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-course',
    component: SchemeAddCourseToSection,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-quiz',
    component: SchemeAddQuizToSection,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-attachment',
    component: SchemeAddAttachmentToSection,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/attachment/:attachmentId/edit',
    component: SchemeSectionAttachmentEdit,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-external-link',
    component: SchemeAddExternalLink,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/section/:sectionId/add-event',
    component: SchemeAddEvent,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/assignment/:assignmentId/edit',
    component: SchemeEditAssignment,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/external-link/:actId/edit',
    component: SchemeEditExternalLink,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/event/:eventId/edit',
    component: SchemeEventEdit,
    requiredClaims: { admin: true }
  },
  {
    path: '/scheme/:id/add-checklist',
    component: SchemeAddChecklist,
    requiredClaims: { admin: true }
  },
  //  checklists
  {
    path: '/checklists',
    component: Checklists,
    requiredClaims: { admin: true }
  },
  {
    path: '/checklists/:id',
    component: Checklist,
    requiredClaims: { admin: true }
  },
  {
    path: '/workflows',
    component: Workflows,
    requiredClaims: { admin: true, [ROLES.WORKFLOW_ADMIN]: true }
  },
  {
    path: '/workflows/:id',
    component: Workflow,
    requiredClaims: { admin: true, [ROLES.WORKFLOW_ADMIN]: true }
  }
]

export default routes
