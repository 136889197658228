import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import config from 'configs/firebase-config.json'
const firebaseApp: firebase.app.App = firebase.initializeApp(config)

const initFirestore = () => {
  const db = firebaseApp.firestore()
  // db.settings({ host: 'localhost:8080', ssl: false })
  return db
}

const initFunctions = () => {
  const functions = firebaseApp.functions('asia-east2')
  // functions.useFunctionsEmulator('http://localhost:5001')

  return functions
}

export const auth = firebaseApp.auth()
export const db = initFirestore()
export const functions = initFunctions()
export default firebaseApp
