export function hasAnyRoles(
    roles: { [key: string]: boolean },
    ...requireRoles: string[]
  ) {
    let hasPermission = false
  
    requireRoles.forEach((role) => {
      if (roles[role] === true) {
        hasPermission = true
      }
    })
  
    return hasPermission
  }